import { Route } from "react-router-dom";
import Routes from "./components/TopLoadingBar";
import DashboardAdminLayout from "./components/dashboard/layout/Dashboard-admin";
import AdminRoutes from "./routes/admin.route";
import SignInAdmin from "./pages/Authenticate/Admin/Signin";
import AdminGuard from "./middleware/admin.guard";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={ <AdminGuard>
            <SignInAdmin />
          </AdminGuard>} />
      {/* ADMIN */}
      <Route path="/:lang/dashboard/admin" element={<DashboardAdminLayout />}>
        {AdminRoutes}
      </Route>
    </Routes>
  );
}

export default App;
